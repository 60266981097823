<script>
export default {
  name: "AppCart",
};
</script>

<template>
  <h1>Cart page</h1>
</template>

<style scoped></style>